// @flow
import JQueryView, { on } from '../common/JQueryView';
import { browserName } from '../../helpers/ApplicationHelper';

declare var $: any;

export default class ShowView extends JQueryView {
  tocItems = [];
  isMobile: boolean;
  registrationModalHeight: number;

  constructor(isMobile: boolean) {
    super('#sb-site');
    this.isMobile = isMobile;
    this.registrationModalHeight = 0;

    this.renderContentBox();
    this.renderSummary();
    this.renderToc();
    this.handleToc();
    this.renderSupporter();
    this.renderTeam();
    this.renderMenter();
    this.renderReadMore();
    this.renderMileStone();

    if ($('.header-funding-amount').length > 0) {
      if ($('.header-funding-amount').css('display') != 'none') {
        $('.to-page-top-wrapper').addClass('bottom-offset');
      }
    }

    $('.header-funding-amount').css({
      position: browserName() === 'safari' ? '-webkit-sticky' : 'sticky',
      bottom: 0,
    });
    this.handleToPageTop();
    this.setRegistrationModalHeight();
    this.handleRegistrationModal();
    this.loadFacebookSdk();
  }

  @on('click .modal-social__btn.share')
  onClickPopup(event: any) {
    event.preventDefault();
    const intWidth = '500';
    const intHeight = '400';
    const strTitle =
        typeof event.currentTarget.title !== 'undefined'
          ? event.currentTarget.title
          : 'Social Share',
      strParam = `width= ${intWidth} ,height= ${intHeight}`;
    window.open(event.currentTarget.href, strTitle, strParam).focus();
  }

  @on('click #btn_to_page_top')
  onClickBtnToPageTop(event: any) {
    event.preventDefault();
    const position = $('html').offset().top;
    const miriSecMove = 1000;
    $('html, body').animate({ scrollTop: position }, miriSecMove);
  }

  handleToPageTop() {
    const toPageTop = $('.to-page-top');
    toPageTop.hide();

    const thisWindow = $(window);
    $(window).scroll(() => {
      const targetHeight = 1500;
      if ($(thisWindow).scrollTop() > targetHeight) {
        toPageTop.fadeIn();
      } else {
        toPageTop.fadeOut();
      }
    });
  }

  handleToc() {
    const offsetHeight = 140;
    $('body').scrollspy({ target: '.toc', offset: offsetHeight });

    if ($('.header-funding-amount').length > 0) {
      $('#side_toc').hover(
        () => {
          $('.header-funding-amount').fadeOut(200);
        },
        () => {
          $('.header-funding-amount').fadeIn(200);
        },
      );
    }
  }

  renderToc() {
    const selector = '.mokuji';

    $('#detail_content')
      .find(selector)
      .each((i, item) => {
        const trimed = item.dataset.tocTitle
          ? item.dataset.tocTitle.trim()
          : item.textContent.trim();
        const text = trimed.replace('■ ', '').replace('■', '');

        if (text !== '') {
          const index = item.id || `toc-${i}`;
          const sanitizedClassName = selector
            .replace(/((:+[\w-\d]*)|[^A-z0-9-\s])/g, ' ')
            .replace(/\s{2,}/g, ' ')
            .trim();
          const subTocClassName = $(item).hasClass('mokuji-sub')
            ? 'toc-sub'
            : '';
          const className = `toc-${sanitizedClassName} ${subTocClassName}`;

          if (item.id !== index) {
            item.id = index;
          }

          this.tocItems.push({ index, text, className });
        }
      });

    if (this.tocItems.length > 0) {
      let html = '<div class="list-group" data-turbolinks="false">';
      const triggerOptions = [];

      this.tocItems.forEach((item, j) => {
        const options = {
          el: `.toc-li-${j}`,
          fixed: 'true',
          start: `#${item.index}`,
          position: 'top',
          positionEnd: 'top',
          className: 'toc-visible',
        };
        const addClassName = j === 0 ? 'active' : '';
        html += `\n<a href="#${item.index}" class="list-group-item list-group-item-action toc-li-${j} ${item.className} ${addClassName}">${item.text}</a>`;
        triggerOptions.push(options);
      });
      html += '</ul>';
      $('.toc').html(html);
    } else {
      $('.toc').hide();
    }
  }

  setRegistrationModalHeight() {
    const modal = document.getElementById('blurred_modal');
    const element = document.getElementById('only_registered');
    if (!modal || !element) {
      return;
    }

    const position = element.getBoundingClientRect().top + window.scrollY;
    this.registrationModalHeight = position + 300;
    modal.style.top = `${position + 300}px`;
  }

  handleRegistrationModal() {
    const modal = document.getElementById('blurred_modal');
    const footer = document.getElementById('l-footer');
    const header = document.getElementById('g-header');
    if (!modal || !footer || !header) {
      return;
    }

    $(window).scroll(() => {
      const currentScroll =
        window.scrollY ||
        (document.documentElement ? document.documentElement.scrollTop : 0);
      const viewportHeight = window.innerHeight;
      const triggerPoint =
        this.registrationModalHeight -
        (viewportHeight / 2 - modal.offsetHeight / 2) +
        header.offsetHeight;

      if (
        currentScroll >= triggerPoint &&
        currentScroll < footer.offsetTop - viewportHeight
      ) {
        modal.classList.add('fixed');
        modal.style.top = `${viewportHeight / 2 - modal.offsetHeight / 2}px`;
      } else if (currentScroll >= footer.offsetTop - viewportHeight) {
        modal.classList.remove('fixed');
        modal.style.top = `${footer.offsetTop -
          modal.offsetHeight / 2 -
          viewportHeight / 2 -
          header.offsetHeight}px`;
      } else {
        modal.classList.remove('fixed');
        modal.style.top = `${this.registrationModalHeight}px`;
      }
    });
  }

  @on('click .toc-mokuji')
  onClickTocMokuji(event: any) {
    event.preventDefault();
    $('#model_toc').modal('hide');

    const href = $(event.currentTarget).attr('href');
    const target = $(href == '#' || href === '' ? 'html' : href);
    const addOffset = this.isMobile === true ? 115 : 40;
    const position = target.offset().top - addOffset;

    const miriSecMove = 1000;
    $('html, body').animate({ scrollTop: position }, miriSecMove);
  }

  renderSummary() {
    $('#description_summary').html();
    $('#description_summary').html(
      '<div class="summary" data-turbolinks="false"></div>',
    );
    $('.summary').append(
      '<h2 class="mokuji for-summary">Summary -サマリー-</h2>',
    );
    $('#description_body')
      .find('.add-to-summary')
      .each((i, item) => {
        this.renderSummaryBox(item, i + 1);
      });
  }

  renderSummaryBox(itemBox: any, boxNumber: number) {
    const itemBlockTitles = $(itemBox).find('h2');
    let htmlTitle = '';
    if (itemBlockTitles.length > 0) {
      const textTitle = itemBlockTitles[0].textContent.trim();
      htmlTitle = `<p class="title">${textTitle}</p>`;
    }

    $(itemBox)
      .find('h3')
      .each((i, item) => {
        const idName = `summary_${boxNumber}_${i + 1}`;
        $(item).attr('id', idName);
      });

    // 本文からh3を抽出してサマリー用のhtmlを作る処理
    let htmlSubTitles = [];
    $(itemBox)
      .find('h3')
      .each((i, item) => {
        const textSubTitle = item.textContent.trim();
        const itemId = item.id;
        const htmlSubTitle =
          '<p class="sub-title">' +
          `<a class="summary-link" href="#${itemId}">` +
          `● ${textSubTitle}` +
          '</a>' +
          '</p>';
        const hideTitle = $(item).hasClass('no-use-summary');
        if (hideTitle === false) {
          htmlSubTitles.push(htmlSubTitle);
        }
      });

    let html = '<div class="summary-block">';
    html += htmlTitle;
    html += htmlSubTitles.join('');
    html += '</div>';
    $('.summary').append(html);
  }

  @on('click .summary-link')
  onClickSummaryLink(event: any) {
    event.preventDefault();
    const href = $(event.currentTarget).attr('href');
    const target = $(href == '#' || href === '' ? 'html' : href);
    const addOffset = this.isMobile === true ? 115 : 40;
    const position = target.offset().top - addOffset;

    const miriSecMove = 1000;
    $('html, body').animate({ scrollTop: position }, miriSecMove);
  }

  renderContentBox() {
    // コンテンツ枠ごとにid付与 (リンク先にするため)
    $('#description_body')
      .find('.content-box')
      .each((index, item) => {
        $(item).attr('id', `content_box_${index + 1}`);
      });

    // 見出しの自動採番をいったん全削除しないと2重表示されるバグがあるので対応した
    $('#description_body')
      .find('.header-prefix-number')
      .each((index, item) => {
        $(item).remove();
      });

    // 見出しの自動採番処理
    $('#description_body')
      .find('.add-auto-number')
      .each((indexBlock, itemBlock) => {
        $(itemBlock)
          .find('h2.mokuji')
          .prepend(
            '<span class="header-prefix-number">' +
              `${indexBlock + 1}. ` +
              '</span>',
          );
      });

    // コンテンツナビをいったん全削除しないと2重表示されるバグがあるので対応した
    $('#description_body')
      .find('.content-navi-wrapper')
      .each((index, item) => {
        $(item).remove();
      });

    // ここからコンテンツナビを生成
    const itemContentBlocks = $('#description_body').find('.add-navi');
    $(itemContentBlocks).each((index, item) => {
      const htmlNavi = this.structContentNavi(index, itemContentBlocks);
      $(item).prepend(htmlNavi);
    });
  }

  structContentNavi(currentContentIndex: number, itemContentBlocks: any) {
    let htmlNavi = '<div class="content-navi-wrapper">';
    htmlNavi += '<div class="content-navi">';
    htmlNavi += '<div class="content-navi-border"></div>';
    $(itemContentBlocks).each((indexBlock, itemBlock) => {
      let classBasic;
      if (indexBlock < currentContentIndex) {
        classBasic = 'content-navi-link content-navi-past';
      } else if (indexBlock === currentContentIndex) {
        classBasic =
          'content-navi-link content-navi-current content-navi-margin-right';
      } else {
        classBasic = 'content-navi-link content-navi-future';
      }

      htmlNavi +=
        `<a class="${classBasic}" href="#${itemBlock.id}">` +
        `${indexBlock + 1}` +
        '</a>';
    });
    htmlNavi += '</div>';
    htmlNavi += '</div>';

    const isLastBlock = currentContentIndex === itemContentBlocks.length - 1;
    if (isLastBlock) {
      htmlNavi = htmlNavi.replace(
        'content-navi-margin-right',
        'content-navi-margin-left',
      );
    }
    return htmlNavi;
  }

  @on('click .content-navi-link')
  onClickContentNaviLink(event: any) {
    event.preventDefault();
    const href = $(event.currentTarget).attr('href');
    const target = $(href == '#' || href === '' ? 'html' : href);
    const addOffset = this.isMobile === true ? 115 : 0;
    const position = target.offset().top - addOffset;

    const miriSecMove = 1000;
    $('html, body').animate({ scrollTop: position }, miriSecMove);
  }

  renderSupporter() {
    $('#supporter').attr('data-turbolinks', false);
    $('#supporter')
      .find('.supporter-block')
      .each((i, item) => {
        const idName = `supporter_${i + 1}`;
        $(item).attr('id', idName);
        if (i === 0) {
          $(item).addClass('active');
        }
      });

    // メンバー一覧が2重に表示されるバグがあるので、一旦クリアする
    $('.supporter-prev').remove();
    $('.supporter-next').remove();
    $('.supporter-count').remove();
    $('.supporter-others-wrapper').remove();

    $('#supporter').append('<div class="supporter-prev"></div>');
    $('#supporter').append('<div class="supporter-next"></div>');
    $('#supporter').append('<div class="supporter-count"></div>');

    let htmlPersonLinks = [];
    $('#supporter')
      .find('.supporter-block')
      .each((i, item) => {
        const itemPersonName = $(item).find('.full-name');
        const textPersonName = itemPersonName[0].textContent.trim();

        const itemPersonPosition = $(item).find('.supporter-position');
        const textPersonPosition = itemPersonPosition[0].textContent.trim();

        const itemPersonPicture = $(item).find('.supporter-picture');
        const htmlPersonPicture = $(itemPersonPicture[0]).html();

        let htmlLink = '<a class="supporter-link" href="#supporter_header">';
        htmlLink += '<div class="link-wrapper">';
        htmlLink += `<div class="link-picture">${htmlPersonPicture}</div>`;
        htmlLink += '<div class="link-description">';
        htmlLink += `<div class="link-position">${textPersonPosition}</div>`;
        htmlLink += `<div class="link-name">${textPersonName}</div>`;
        htmlLink += '</div>';
        htmlLink += '</div>';
        htmlLink += '</a>';
        htmlPersonLinks.push(htmlLink);
      });

    let html = '<div class="supporter-others-wrapper">';
    html += '<div class="supporter-others">';
    html += htmlPersonLinks.join('');
    html += '</div>';
    html += '</div>';
    $('#supporter').append(html);

    const first_supporter_index = 0;
    this.switchSupporter(first_supporter_index);
  }

  @on('click .supporter-link')
  onClickSupporterLink(event: any) {
    event.preventDefault();
    const href = $(event.currentTarget).attr('href');
    const target = $(href == '#' || href === '' ? 'html' : href);
    const addOffset = this.isMobile === true ? 40 : 0;
    const position = target.offset().top - addOffset;
    const miriSecMove = 500;
    $('html, body').animate({ scrollTop: position }, miriSecMove);

    const targetIndex = $(event.currentTarget).index('.supporter-link');

    setTimeout(() => {
      this.switchSupporter(targetIndex);
    }, miriSecMove);
  }

  @on('click .supporter-prev')
  onClickSupporterPrev() {
    const itemBlocks = $('#supporter').find('.supporter-block');
    if (itemBlocks.length === 0) {
      return;
    }

    const itemActive = $('#supporter').find('.active');
    const currentIndex = $(itemBlocks).index($(itemActive));
    let targetIndex;
    if (currentIndex === 0) {
      targetIndex = itemBlocks.length - 1;
    } else {
      targetIndex = currentIndex - 1;
    }
    this.switchSupporter(targetIndex);
  }

  @on('click .supporter-next')
  onClickSupporterNext() {
    const itemBlocks = $('#supporter').find('.supporter-block');
    if (itemBlocks.length === 0) {
      return;
    }

    const itemActive = $('#supporter').find('.active');
    const currentIndex = $(itemBlocks).index($(itemActive));
    let targetIndex;
    if (currentIndex === itemBlocks.length - 1) {
      targetIndex = 0;
    } else {
      targetIndex = currentIndex + 1;
    }
    this.switchSupporter(targetIndex);
  }

  switchSupporter(switchIndex: number) {
    const personCount = $('#supporter').find('.supporter-block').length;
    const personCountText = `${switchIndex + 1} of ${personCount}`;
    const itemPersonCount = $('#supporter').find('.supporter-count');
    $(itemPersonCount[0]).html(personCountText);

    $('#supporter')
      .find('.supporter-block')
      .each((i, item) => {
        if (i === switchIndex) {
          $(item)
            .addClass('active')
            .hide()
            .fadeIn(0);
        } else {
          $(item).removeClass('active');
        }
      });

    $('#supporter')
      .find('.supporter-link')
      .each((i, item) => {
        if (i === switchIndex) {
          $(item).addClass('active');
        } else {
          $(item).removeClass('active');
        }
      });
  }

  renderTeam() {
    $('#team').attr('data-turbolinks', false);
    $('#team')
      .find('.member-block')
      .each((i, item) => {
        const idName = `team_member_${i + 1}`;
        $(item).attr('id', idName);
        if (i === 0) {
          $(item).addClass('active');
        }
      });

    // メンバー一覧が2重に表示されるバグがあるので、一旦クリアする
    $('.member-prev').remove();
    $('.member-next').remove();
    $('.member-count').remove();
    $('.member-others-wrapper').remove();

    $('#team').append('<div class="member-prev"></div>');
    $('#team').append('<div class="member-next"></div>');
    $('#team').append('<div class="member-count"></div>');

    let htmlPersonLinks = [];
    $('#team')
      .find('.member-block')
      .each((i, item) => {
        const itemPersonName = $(item).find('.full-name');
        const textPersonName = itemPersonName[0].textContent.trim();

        const itemPersonPosition = $(item).find('.member-position');
        const textPersonPosition = itemPersonPosition[0].textContent.trim();

        const itemPersonPicture = $(item).find('.member-picture');
        const htmlPersonPicture = $(itemPersonPicture[0]).html();

        let htmlLink = '<a class="member-link" href="#team_header">';
        htmlLink += '<div class="link-wrapper">';
        htmlLink += `<div class="link-picture">${htmlPersonPicture}</div>`;
        htmlLink += '<div class="link-description">';
        htmlLink += `<div class="link-position">${textPersonPosition}</div>`;
        htmlLink += `<div class="link-name">${textPersonName}</div>`;
        htmlLink += '</div>';
        htmlLink += '</div>';
        htmlLink += '</a>';
        htmlPersonLinks.push(htmlLink);
      });

    let html = '<div class="member-others-wrapper">';
    html += '<div class="member-others">';
    html += htmlPersonLinks.join('');
    html += '</div>';
    html += '</div>';
    $('#team').append(html);

    const first_member_index = 0;
    this.switchTeamMember(first_member_index);
  }

  @on('click .member-link')
  onClickMemberLink(event: any) {
    event.preventDefault();
    const href = $(event.currentTarget).attr('href');
    const target = $(href == '#' || href === '' ? 'html' : href);
    const addOffset = this.isMobile === true ? 40 : 0;
    const position = target.offset().top - addOffset;
    const miriSecMove = 500;
    $('html, body').animate({ scrollTop: position }, miriSecMove);

    const targetIndex = $(event.currentTarget).index('.member-link');

    setTimeout(() => {
      this.switchTeamMember(targetIndex);
    }, miriSecMove);
  }

  @on('click .member-prev')
  onClickMemberPrev() {
    const itemBlocks = $('#team').find('.member-block');
    if (itemBlocks.length === 0) {
      return;
    }

    const itemActive = $('#team').find('.active');
    const currentIndex = $(itemBlocks).index($(itemActive));
    let targetIndex;
    if (currentIndex === 0) {
      targetIndex = itemBlocks.length - 1;
    } else {
      targetIndex = currentIndex - 1;
    }
    this.switchTeamMember(targetIndex);
  }

  @on('click .member-next')
  onClickMemberNext() {
    const itemBlocks = $('#team').find('.member-block');
    if (itemBlocks.length === 0) {
      return;
    }

    const itemActive = $('#team').find('.active');
    const currentIndex = $(itemBlocks).index($(itemActive));
    let targetIndex;
    if (currentIndex === itemBlocks.length - 1) {
      targetIndex = 0;
    } else {
      targetIndex = currentIndex + 1;
    }
    this.switchTeamMember(targetIndex);
  }

  switchTeamMember(switchIndex: number) {
    const personCount = $('#team').find('.member-block').length;
    const personCountText = `${switchIndex + 1} of ${personCount}`;
    const itemPersonCount = $('#team').find('.member-count');
    $(itemPersonCount[0]).html(personCountText);

    $('#team')
      .find('.member-block')
      .each((i, item) => {
        if (i === switchIndex) {
          $(item)
            .addClass('active')
            .hide()
            .fadeIn(0);
        } else {
          $(item).removeClass('active');
        }
      });

    $('#team')
      .find('.member-link')
      .each((i, item) => {
        if (i === switchIndex) {
          $(item).addClass('active');
        } else {
          $(item).removeClass('active');
        }
      });
  }

  renderMenter() {
    $('#menter').attr('data-turbolinks', false);
    $('#menter')
      .find('.menter-block')
      .each((i, item) => {
        const idName = `menter_${i + 1}`;
        $(item).attr('id', idName);
        if (i === 0) {
          $(item).addClass('active');
        }
      });

    // メンター一覧が2重に表示されるバグがあるので、一旦クリアする
    $('.menter-prev').remove();
    $('.menter-next').remove();
    $('.menter-count').remove();
    $('.menter-others-wrapper').remove();

    $('#menter').append('<div class="menter-prev"></div>');
    $('#menter').append('<div class="menter-next"></div>');
    $('#menter').append('<div class="menter-count"></div>');

    let htmlPersonLinks = [];
    $('#menter')
      .find('.menter-block')
      .each((i, item) => {
        const itemPersonName = $(item).find('.full-name');
        const textPersonName = itemPersonName[0].textContent.trim();

        const itemPersonPosition = $(item).find('.menter-position');
        const textPersonPosition = itemPersonPosition[0].textContent.trim();

        const itemPersonPicture = $(item).find('.menter-picture');
        const htmlPersonPicture = $(itemPersonPicture[0]).html();

        let htmlLink = '<a class="menter-link" href="#menter_header">';
        htmlLink += '<div class="link-wrapper">';
        htmlLink += `<div class="link-picture">${htmlPersonPicture}</div>`;
        htmlLink += '<div class="link-description">';
        htmlLink += `<div class="link-position">${textPersonPosition}</div>`;
        htmlLink += `<div class="link-name">${textPersonName}</div>`;
        htmlLink += '</div>';
        htmlLink += '</div>';
        htmlLink += '</a>';
        htmlPersonLinks.push(htmlLink);
      });

    let html = '<div class="menter-others-wrapper">';
    html += '<div class="menter-others">';
    html += htmlPersonLinks.join('');
    html += '</div>';
    html += '</div>';
    $('#menter').append(html);

    const first_menter_index = 0;
    this.switchMenter(first_menter_index);
  }

  @on('click .menter-link')
  onClickMenterLink(event: any) {
    event.preventDefault();

    const href = $(event.currentTarget).attr('href');
    const target = $(href == '#' || href === '' ? 'html' : href);
    const addOffset = this.isMobile === true ? 40 : 0;
    const position = target.offset().top - addOffset;
    const miriSecMove = 500;
    $('html, body').animate({ scrollTop: position }, miriSecMove);

    const targetIndex = $(event.currentTarget).index('.menter-link');
    setTimeout(() => {
      this.switchMenter(targetIndex);
    }, miriSecMove);
  }

  @on('click .menter-prev')
  onClickMenterPrev() {
    const itemBlocks = $('#menter').find('.menter-block');
    if (itemBlocks.length === 0) {
      return;
    }

    const itemActive = $('#menter').find('.active');
    const currentIndex = $(itemBlocks).index($(itemActive));
    let targetIndex;
    if (currentIndex === 0) {
      targetIndex = itemBlocks.length - 1;
    } else {
      targetIndex = currentIndex - 1;
    }
    this.switchMenter(targetIndex);
  }

  @on('click .menter-next')
  onClickMenterNext() {
    const itemBlocks = $('#menter').find('.menter-block');
    if (itemBlocks.length === 0) {
      return;
    }

    const itemActive = $('#menter').find('.active');
    const currentIndex = $(itemBlocks).index($(itemActive));
    let targetIndex;
    if (currentIndex === itemBlocks.length - 1) {
      targetIndex = 0;
    } else {
      targetIndex = currentIndex + 1;
    }
    this.switchMenter(targetIndex);
  }

  switchMenter(switchIndex: number) {
    const personCount = $('#menter').find('.menter-block').length;
    const personCountText = `${switchIndex + 1} of ${personCount}`;
    const itemPersonCount = $('#menter').find('.menter-count');
    $(itemPersonCount[0]).html(personCountText);

    $('#menter')
      .find('.menter-block')
      .each((i, item) => {
        if (i === switchIndex) {
          $(item)
            .addClass('active')
            .hide()
            .fadeIn(0);
        } else {
          $(item).removeClass('active');
        }
      });

    $('#menter')
      .find('.menter-link')
      .each((i, item) => {
        if (i === switchIndex) {
          $(item).addClass('active');
        } else {
          $(item).removeClass('active');
        }
      });
  }

  renderReadMore() {
    // 「続きを読む」ボタンをいったん全削除しないと2重表示されるバグがあるので対応した
    $('#description_body')
      .find('.read-more-triggar')
      .each((index, item) => {
        $(item).remove();
      });

    $('#detail_content')
      .find('.read-more-wrapper')
      .each((i, item) => {
        $(item).append(
          '<div class="read-more-triggar triggar-open">続きを読む</div>',
        );
      });

    $('#detail_content')
      .find('.read-more-body')
      .each((i, item) => {
        $(item).addClass('read-more-closed');
      });
  }

  @on('click .read-more-triggar')
  onClickReadMoreTriggar(event: any) {
    if ($(event.currentTarget).hasClass('triggar-close')) {
      this.closeReadMore(event);
    } else {
      this.openReadMore(event);
    }
  }

  openReadMore(event: any) {
    const itemWrap = $(event.currentTarget).closest('.read-more-wrapper');
    $(itemWrap)
      .find('.read-more-body')
      .addClass('read-more-opened')
      .removeClass('read-more-closed');

    $(itemWrap)
      .find('.read-more-triggar')
      .removeClass('triggar-open')
      .addClass('triggar-close')
      .html('折りたたむ');
  }

  closeReadMore(event: any) {
    const miriSecScroll = 500;
    const itemWrap = $(event.currentTarget).closest('.read-more-wrapper');
    const addOffset = this.isMobile === true ? 115 : 40;
    const position = itemWrap.offset().top - addOffset;

    $('html, body').animate({ scrollTop: position }, miriSecScroll);

    setTimeout(() => {
      $(itemWrap)
        .find('.read-more-body')
        .addClass('read-more-closed')
        .removeClass('read-more-opened');

      $(itemWrap)
        .find('.read-more-triggar')
        .removeClass('triggar-close')
        .addClass('triggar-open')
        .html('続きを読む');
    }, miriSecScroll);
  }

  renderMileStone() {
    $('#detail_content')
      .find('.milestone-block')
      .each((i, itemBlock) => {
        const itemBody = $(itemBlock).children('.milestone-body');
        const itemTitle = $(itemBlock).children('.milestone-title');
        const is_first_block = i == 0;
        if (is_first_block) {
          $(itemBody).addClass('milestone-opened');
          $(itemTitle).addClass('triggar-close');
        } else {
          $(itemBody).addClass('milestone-closed');
          $(itemTitle).addClass('triggar-open');
        }
      });
  }

  @on('click .milestone-title')
  onClickMileStoneTitle(event: any) {
    if ($(event.currentTarget).hasClass('triggar-close')) {
      this.closeMileStoneBlock(event);
    } else {
      this.openMileStoneBlock(event);
    }
  }

  openMileStoneBlock(event: any) {
    const itemBlock = $(event.currentTarget).closest('.milestone-block');
    $(itemBlock)
      .find('.milestone-body')
      .removeClass('milestone-closed')
      .addClass('milestone-opened');

    $(itemBlock)
      .find('.milestone-title')
      .removeClass('triggar-open')
      .addClass('triggar-close');
  }

  closeMileStoneBlock(event: any) {
    const itemBlock = $(event.currentTarget).closest('.milestone-block');
    $(itemBlock)
      .find('.milestone-body')
      .removeClass('milestone-opened')
      .addClass('milestone-closed');

    $(itemBlock)
      .find('.milestone-title')
      .removeClass('triggar-close')
      .addClass('triggar-open');
  }

  @on('click .answer-open')
  onClickAnswerOpen(event: any) {
    $(event.currentTarget).hide();
    $(event.currentTarget)
      .closest('.card-body-answer')
      .find('.answer-body')
      .removeClass('d-none');
  }

  loadFacebookSdk() {
    (function(d, s, id) {
      if (d.getElementById(id)) {
        return;
      }

      const fjs: any = d.getElementsByTagName(s)[0];
      let js = d.createElement(s);
      js.id = id;
      // $FlowFixMe
      js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.1';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'footer', 'facebook-jssdk');
  }
}
