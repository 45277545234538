// @flow
import JQueryView, { on } from '../common/JQueryView';

export default class FormView extends JQueryView {
  constructor() {
    super('.simple_form');

    $('input, select, textarea').each(function() {
      const $el = $(this);
      if ($el.val() !== '' && !$el.hasClass('is-invalid')) {
        $el.addClass('is-activated');
      }
    });
  }

  @on('change input,select,textarea')
  onChangeInputStyle(e: any) {
    const $el = $(e.currentTarget);

    if ($el.val() === '') {
      $el.removeClass('is-activated');
    } else {
      $el.addClass('is-activated').removeClass('is-invalid');

      $el
        .parents('.form-group')
        .find('.invalid-feedback')
        .hide();
    }
  }
}
