// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  driverLicense1Invalid: any;
  driverLicense2Invalid: any;
  passport1Invalid: any;
  passport2Invalid: any;
  residentRegisterInvalid: any;
  residentRegisterBackInvalid: any;
  myNumber1Invalid: any;
  publicReceipt1Invalid: any;
  insurance1Invalid: any;
  insurance2Invalid: any;
  residenceCertificateInvalid: any;
  sealRegistrationInvalid: any;
  driverLicense1: any;
  driverLicense2: any;
  passport1: any;
  passport2: any;
  residentRegister: any;
  residentRegisterBack: any;
  myNumber1: any;
  publicReceipt1: any;
  publicReceipt2: any;
  insurance1: any;
  insurance2: any;
  residenceCertificate: any;
  sealRegistration: any;
  kycType: any;
  validSelectedIdentification: boolean;
  errorCounterActive: boolean;
  scrollIndex: number;

  constructor(
    driverLicense1: string,
    driverLicense2: string,
    passport1: string,
    passport2: string,
    residentRegister: string,
    residentRegisterBack: string,
    myNumber1: string,
    publicReceipt1: string,
    insurance1: string,
    insurance2: string,
    residenceCertificate: string,
    sealRegistration: string,
    kycType: number,
    driverLicense1Invalid: any,
    driverLicense2Invalid: any,
    passport1Invalid: any,
    passport2Invalid: any,
    residentRegisterInvalid: any,
    residentRegisterBackInvalid: any,
    myNumber1Invalid: any,
    publicReceipt1Invalid: any,
    insurance1Invalid: any,
    insurance2Invalid: any,
    residenceCertificateInvalid: any,
    sealRegistrationInvalid: any,
  ) {
    super('.conf-document');
    this.driverLicense1 = driverLicense1;
    this.driverLicense2 = driverLicense2;
    this.passport1 = passport1;
    this.passport2 = passport2;
    this.residentRegister = residentRegister;
    this.residentRegisterBack = residentRegisterBack;
    this.myNumber1 = myNumber1;
    this.publicReceipt1 = publicReceipt1;
    this.insurance1 = insurance1;
    this.insurance2 = insurance2;
    this.residenceCertificate = residenceCertificate;
    this.sealRegistration = sealRegistration;
    this.kycType = kycType;
    this.driverLicense1Invalid = driverLicense1Invalid;
    this.driverLicense2Invalid = driverLicense2Invalid;
    this.passport1Invalid = passport1Invalid;
    this.passport2Invalid = passport2Invalid;
    this.residentRegisterInvalid = residentRegisterInvalid;
    this.residentRegisterBackInvalid = residentRegisterBackInvalid;
    this.myNumber1Invalid = myNumber1Invalid;
    this.publicReceipt1Invalid = publicReceipt1Invalid;
    this.insurance1Invalid = insurance1Invalid;
    this.insurance2Invalid = insurance2Invalid;
    this.residenceCertificateInvalid = residenceCertificateInvalid;
    this.sealRegistrationInvalid = sealRegistrationInvalid;

    // 書類選択のチェックフラグ
    this.validSelectedIdentification = false;
    // エラーカウンタの表示フラグ
    this.errorCounterActive = false;
    this.scrollIndex = 0;

    // 再レンダリング（保存済みかエラー返却）の場合
    if (kycType) {
      // 提出方法を選択状態にする
      $(`#kyc_type_${kycType}`).prop('checked', true);
      const index = $('.kyc-type').index(':checked');
      this.kycTypeContentToggle(index);

      this.validSelectedIdentification = true;
      this.errorCounterActive = true;
    }

    this.render();
  }

  @on('change input[name="identification[identification_type]"]:radio')
  onChengeIdentificationType() {
    this.render();
  }

  @on('change .kyc-type')
  onChengeKycType(e: any) {
    this.kycType = $(e.currentTarget).val();
    this.render();
  }

  @on('change .document-form input[type=checkbox]')
  onChangeCheckbox() {
    this.validSelectedIdentification = true;
    this.render();
  }

  @on('change input[name="identification[is_same_address]"]')
  onChangeIsAddressEdit() {
    this.render();
  }

  @on('change #identification_driver_license1')
  onChangeDriverLicense1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.driverLicense1 = file.name;
      this.driverLicense1Invalid = false;
      this.render();

      $('#delete_driver_license1').val('');
    } else {
      $('#identification_driver_license1').val('');
    }
  }

  @on('change #identification_driver_license2')
  onChangeDriverLicense2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.driverLicense2 = file.name;
      this.driverLicense2Invalid = false;
      this.render();

      $('#delete_driver_license2').val('');
    } else {
      $('#identification_driver_license2').val('');
    }
  }

  @on('change #identification_passport1')
  onChangePassport1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.passport1 = file.name;
      this.passport1Invalid = false;
      this.render();

      $('#delete_passport1').val('');
    } else {
      $('#identification_passport1').val('');
    }
  }

  @on('change #identification_passport2')
  onChangePassport2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.passport2 = file.name;
      this.passport2Invalid = false;
      this.render();

      $('#delete_passport2').val('');
    } else {
      $('#identification_passport2').val('');
    }
  }

  @on('change #identification_resident_register')
  onChangeResidentRegister(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.residentRegister = file.name;
      this.residentRegisterInvalid = false;
      this.render();

      $('#delete_resident_register').val('');
    } else {
      $('#identification_resident_register').val('');
    }
  }

  @on('change #identification_resident_register_back')
  onChangeResidentRegisterBack(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.residentRegisterBack = file.name;
      this.residentRegisterBackInvalid = false;
      this.render();

      $('#delete_resident_register_back').val('');
    } else {
      $('#identification_resident_register_back').val('');
    }
  }

  @on('change #identification_my_number1')
  onChangeMyNumber1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.myNumber1 = file.name;
      this.myNumber1Invalid = false;
      this.render();

      $('#delete_my_number1').val('');
    } else {
      $('#identification_my_number1').val('');
    }
  }

  @on('change #identification_public_receipt1')
  onChangePublicReceipt1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.publicReceipt1 = file.name;
      this.publicReceipt1Invalid = false;
      this.render();

      $('#delete_public_receipt1').val('');
    } else {
      $('#identification_public_receipt1').val('');
    }
  }

  @on('change #identification_insurance1')
  onChangeInsurance1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.insurance1 = file.name;
      this.insurance1Invalid = false;
      this.render();

      $('#delete_insurance1').val('');
    } else {
      $('#identification_insurance1').val('');
    }
  }

  @on('change #identification_insurance2')
  onChangeInsurance2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.insurance2 = file.name;
      this.insurance2Invalid = false;
      this.render();

      $('#delete_insurance2').val('');
    } else {
      $('#identification_insurance2').val('');
    }
  }

  @on('change #identification_residence_certificate')
  onChangeResidenceCertificate(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.residenceCertificate = file.name;
      this.residenceCertificateInvalid = false;
      this.render();

      $('#delete_residence_certificate').val('');
    } else {
      $('#identification_residence_certificate').val('');
    }
  }

  @on('change #identification_seal_registration')
  onChangeSealRegistration(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type.indexOf('image/') === 0) {
      this.sealRegistration = file.name;
      this.sealRegistrationInvalid = false;
      this.render();

      $('#delete_seal_registration').val('');
    } else {
      $('#identification_seal_registration').val('');
    }
  }

  @on('click #remove_driver_license1')
  onClickRemoveDriverLicense1() {
    $('#identification_driver_license1').val('');
    this.driverLicense1 = '';
    $('#remove_driver_license1').hide();
    $('#delete_driver_license1').val('1');
    this.render();
  }

  @on('click #remove_driver_license2')
  onClickRemoveDriverLicense2() {
    $('#identification_driver_license2').val('');
    this.driverLicense2 = '';
    $('#remove_driver_license2').hide();
    $('#delete_driver_license2').val('1');
    this.render();
  }

  @on('click #remove_passport1')
  onClickRemovePassport1() {
    $('#identification_passport1').val('');
    this.passport1 = '';
    $('#remove_passport1').hide();
    $('#delete_passport1').val('1');
    this.render();
  }

  @on('click #remove_passport2')
  onClickRemovePassport2() {
    $('#identification_passport2').val('');
    this.passport2 = '';
    $('#remove_passport2').hide();
    $('#delete_passport2').val('1');
    this.render();
  }

  @on('click #remove_resident_register')
  onClickRemoveResidentRegister() {
    $('#identification_resident_register').val('');
    this.residentRegister = '';
    $('#remove_resident_register').hide();
    $('#delete_resident_register').val('1');
    this.render();
  }

  @on('click #remove_resident_register_back')
  onClickRemoveResidentRegisterBack() {
    $('#identification_resident_register_back').val('');
    this.residentRegisterBack = '';
    $('#remove_resident_register_back').hide();
    $('#delete_resident_register_back').val('1');
    this.render();
  }

  @on('click #remove_my_number1')
  onClickRemoveMyNumber1() {
    $('#identification_my_number1').val('');
    this.myNumber1 = '';
    $('#remove_my_number1').hide();
    $('#delete_my_number1').val('1');
    this.render();
  }

  @on('click #remove_public_receipt1')
  onClickRemovePublicReceipt1() {
    $('#identification_public_receipt1').val('');
    this.publicReceipt1 = '';
    $('#remove_public_receipt1').hide();
    $('#delete_public_receipt1').val('1');
    this.render();
  }

  @on('click #remove_insurance1')
  onClickRemoveinsurance1() {
    $('#identification_insurance1').val('');
    this.insurance1 = '';
    $('#remove_insurance1').hide();
    $('#delete_insurance1').val('1');
    this.render();
  }

  @on('click #remove_insurance2')
  onClickRemoveinsurance2() {
    $('#identification_insurance2').val('');
    this.insurance2 = '';
    $('#remove_insurance2').hide();
    $('#delete_insurance2').val('1');
    this.render();
  }

  @on('click #remove_residence_certificate')
  onClickRemoveResidenceCertificate() {
    $('#identification_residence_certificate').val('');
    this.residenceCertificate = '';
    $('#remove_residence_certificate').hide();
    $('#delete_residence_certificate').val('1');
    this.render();
  }

  @on('click #remove_seal_registration')
  onClickRemoveSealRegistration() {
    $('#identification_oseal_registration').val('');
    this.sealRegistration = '';
    $('#remove_seal_registration').hide();
    $('#delete_seal_registration').val('1');
    this.render();
  }

  kycTypeContentToggle(index: number) {
    const switchContent = $('.conf-document-select-content');
    const switchItem = $('.conf-document-switch-item');

    $(switchContent).removeClass('is-show');
    $(switchContent)
      .eq(index)
      .addClass('is-show');
    $(switchItem).removeClass('is-contents-active');
    $(switchItem)
      .eq(index)
      .addClass('is-contents-active');
  }

  @on('click .js-licence-toggle')
  onClickLisenceToggle(e: any) {
    const licenceToggle = $(e.currentTarget);
    $(licenceToggle).toggleClass('active');
    $(licenceToggle)
      .next('.licence-toggle-contents')
      .stop()
      .slideToggle(300);
  }

  @on('click .collection_radio_buttons')
  onClickRadioButton(e: any) {
    $(e.currentTarget)
      .closest('.form-group')
      .addClass('form-group-valid');
  }

  render() {
    $('#driver_license').hide();
    $('#passport').hide();
    $('#my_number').hide();
    $('#resident_register').hide();
    $('#insurance').hide();
    $('#residence_certificate').hide();
    $('#seal_registration').hide();

    if ($('#identification_select_driver_license').prop('checked') == true) {
      $('#driver_license').show();
    }
    if ($('#identification_select_passport').prop('checked') == true) {
      $('#passport').show();
    }
    if ($('#identification_select_my_number').prop('checked') == true) {
      $('#my_number').show();
    }
    if ($('#identification_select_resident_register').prop('checked') == true) {
      $('#resident_register').show();
    }
    if ($('#identification_select_insurance').prop('checked') == true) {
      $('#insurance').show();
    }
    if (
      $('#identification_select_residence_certificate').prop('checked') == true
    ) {
      $('#residence_certificate').show();
    }
    if ($('#identification_select_seal_registration').prop('checked') == true) {
      $('#seal_registration').show();
    }

    if (
      $('input[name="identification[is_same_address]"]:checked').val() ===
      'false'
    ) {
      $('#public_receipt').show();
    } else {
      $('#public_receipt').hide();
    }

    if (this.driverLicense1Invalid == true) {
      $('#preview_driver_license1').text('ファイルを選択してください');
      $('#preview_driver_license1').addClass('no-empty-text');
    } else if (this.driverLicense1 === '') {
      $('#preview_driver_license1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_driver_license1').hide();
    } else {
      $('#preview_driver_license1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .removeClass('no-empty-text')
        .append(this.driverLicense1);
      $('#remove_driver_license1').show();
      $('#preview_driver_license1.file-name').removeClass('no-empty-text');
    }

    if (this.driverLicense2Invalid == true) {
      $('#preview_driver_license2').text('裏面も選択してください');
      $('#preview_driver_license2').addClass('no-empty-text');
    } else if (this.driverLicense2 === '') {
      $('#preview_driver_license2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_driver_license2').hide();
    } else {
      $('#preview_driver_license2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .removeClass('no-empty-text')
        .append(this.driverLicense2);
      $('#remove_driver_license2').show();
      $('#preview_driver_license2.file-name').removeClass('no-empty-text');
    }

    if (this.passport1Invalid == true) {
      $('#preview_passport1').text('ファイルを選択してください');
      $('#preview_passport1').addClass('no-empty-text');
      $('.identification_passport1 .invalid-feedback').remove();
    } else if (this.passport1 === '') {
      $('#preview_passport1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_passport1').hide();
    } else {
      $('#preview_passport1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.passport1);
      $('#remove_passport1').show();
      $('#preview_passport1.file-name').removeClass('no-empty-text');
    }

    if (this.passport2Invalid == true) {
      $('#preview_passport2').text('ファイルを選択してください');
      $('#preview_passport2').addClass('no-empty-text');
      $('.identification_passport2 .invalid-feedback').remove();
    } else if (this.passport2 === '') {
      $('#preview_passport2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_passport2').hide();
    } else {
      $('#preview_passport2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.passport2);
      $('#remove_passport2').show();
      $('#preview_passport2.file-name').removeClass('no-empty-text');
    }

    if (this.residentRegisterInvalid == true) {
      $('#preview_resident_register').text('ファイルを選択してください');
      $('#preview_resident_register').addClass('no-empty-text');
      $('.identification_resident_register .invalid-feedback').remove();
    } else if (this.residentRegister === '') {
      $('#preview_resident_register')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_resident_register').hide();
    } else {
      $('#preview_resident_register')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.residentRegister);
      $('#remove_resident_register').show();
      $('#preview_resident_register.file-name').removeClass('no-empty-text');
    }

    if (this.residentRegisterBackInvalid == true) {
      $('#preview_resident_register_back').text('ファイルを選択してください');
      $('#preview_resident_register_back').addClass('no-empty-text');
      $('.identification_resident_register_back .invalid-feedback').remove();
    } else if (this.residentRegisterBack === '') {
      $('#preview_resident_register_back')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_resident_register_back').hide();
    } else {
      $('#preview_resident_register_back')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.residentRegisterBack);
      $('#remove_resident_register_back').show();
      $('#preview_resident_register_back.file-name').removeClass(
        'no-empty-text',
      );
    }

    if (this.myNumber1Invalid == true) {
      $('#preview_my_number1').text('ファイルを選択してください');
      $('#preview_my_number1').addClass('no-empty-text');
      $('.identification_my_number1 .invalid-feedback').remove();
    } else if (this.myNumber1 === '') {
      $('#preview_my_number1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_my_number1').hide();
    } else {
      $('#preview_my_number1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.myNumber1);
      $('#remove_my_number1').show();
      $('#preview_my_number1.file-name').removeClass('no-empty-text');
    }

    if (this.publicReceipt1 === '') {
      $('#preview_public_receipt1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_public_receipt1').hide();
    } else {
      $('#preview_public_receipt1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.publicReceipt1);
      $('#remove_public_receipt1').show();
    }

    if (this.insurance1Invalid == true) {
      $('#preview_insurance1').text('ファイルを選択してください');
      $('#preview_insurance1').addClass('no-empty-text');
      $('.identification_insurance1 .invalid-feedback').remove();
    } else if (this.insurance1 === '') {
      $('#preview_insurance1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_insurance1').hide();
    } else {
      $('#preview_insurance1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.insurance1);
      $('#remove_insurance1').show();
      $('#preview_insurance1.file-name').removeClass('no-empty-text');
    }
    if (this.insurance2Invalid == true) {
      $('#preview_insurance2').text('ファイルを選択してください');
      $('#preview_insurance2').addClass('no-empty-text');
      $('.identification_insurance2 .invalid-feedback').remove();
    } else if (this.insurance2 === '') {
      $('#preview_insurance2')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_insurance2').hide();
    } else {
      $('#preview_insurance2')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.insurance2);
      $('#remove_insurance2').show();
      $('#preview_insurance2.file-name').removeClass('no-empty-text');
    }

    if (this.residenceCertificateInvalid == true) {
      $('#preview_residence_certificate').text('ファイルを選択してください');
      $('#preview_residence_certificate').addClass('no-empty-text');
      $('.identification_residence_certificate .invalid-feedback').remove();
    } else if (this.residenceCertificate === '') {
      $('#preview_residence_certificate')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_residence_certificate').hide();
    } else {
      $('#preview_residence_certificate')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.residenceCertificate);
      $('#remove_residence_certificate').show();
      $('#preview_residence_certificate.file-name').removeClass(
        'no-empty-text',
      );
    }

    if (this.sealRegistrationInvalid == true) {
      $('#preview_seal_registration').text('ファイルを選択してください');
      $('#preview_seal_registration').addClass('no-empty-text');
      $('.identification_seal_registration .invalid-feedback').remove();
    } else if (this.sealRegistration === '') {
      $('#preview_seal_registration')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_seal_registration').hide();
    } else {
      $('#preview_seal_registration')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.sealRegistration);
      $('#remove_seal_registration').show();
      $('#preview_seal_registration.file-name').removeClass('no-empty-text');
    }

    if (this.publicReceipt1Invalid == true) {
      $('#preview_public_receipt1').text('ファイルを選択してください');
      $('#preview_public_receipt1').addClass('no-empty-text');
    } else if (this.publicReceipt1 === '') {
      $('#preview_public_receipt1')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_public_receipt1').hide();
    } else {
      $('#preview_public_receipt1')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.publicReceipt1);
      $('#remove_public_receipt1').show();
      $('#preview_public_receipt1.file-name').removeClass('no-empty-text');
    }

    const checkedCount = $('input[type=checkbox]:checked').length;
    const maxChecked = 2;
    const $notChecked = $('input[type=checkbox]').not(':checked');

    if (maxChecked > checkedCount) {
      $notChecked.prop('disabled', false);

      // 初期表示時はエラーを出さない
      if (this.validSelectedIdentification) {
        $('.form-check-label').addClass('error-check-label');
        $('.js-invalid-selected-identification').addClass('d-block');
      }
    } else {
      $notChecked.prop('disabled', true);

      $('.form-check-label').removeClass('error-check-label');
      $('.js-invalid-selected-identification').removeClass('d-block');
    }

    // 書類形式の選択と関連した表示制御
    const $submitBtn = $('.js-identification-indivadual-submit');
    const $ekycBtn = $('.js-identification-ekyc');
    const $ekycDevice = $('.js-ekyc-device-list');
    $submitBtn.addClass('is-hidden');
    $ekycBtn.addClass('is-hidden');
    $ekycDevice.addClass('is-hidden');
    $('.submission').removeClass('selected');

    const kycTypeIndex = $('.kyc-type').index($(':checked'));
    if (kycTypeIndex !== -1) this.kycTypeContentToggle(kycTypeIndex);

    if (this.kycType == 1) {
      $submitBtn.removeClass('is-hidden');
    } else if (this.kycType == 2) {
      $ekycBtn.removeClass('is-hidden');
      // 対応端末について
      $ekycDevice.removeClass('is-hidden');
    }

    // 本人書類との一致ラジオボタン、選択されていれば表示されているエラーを消す
    const checkedIsSameAddressLength = $(
      'input[name="identification[is_same_address]"]',
    ).filter(':checked').length;
    if (checkedIsSameAddressLength) {
      $('.identification_is_same_address').removeClass('form-group-invalid');
      $('.conf-document-licence-same .invalid-feedback').remove();
    }

    // 補完書類の開閉
    if ($('#identification_is_same_address_false').prop('checked')) {
      $('#public_receipt').show();
    } else {
      $('#public_receipt').hide();
    }

    this.fetchErrors();

    return this;
  }

  fetchErrors() {
    if (!this.errorCounterActive) return;

    const num = $('.invalid-feedback:visible, .no-empty-text:visible').length;

    if (num > 0) {
      $('.error-counter-text.error-count').html(num);
      $('.js-error-counter').fadeIn();
    } else {
      $('.js-error-counter').fadeOut();
    }

    if (this.scrollIndex >= num) this.scrollIndex = 0;
  }

  @on('click .error-counter')
  scrollNextError(e: any) {
    const $errors = $('.invalid-feedback:visible, .no-empty-text:visible');

    if ($errors.length === 0) return;

    const basePosition = e.currentTarget.offsetTop;
    const position = $($errors[this.scrollIndex]).offset().top;

    $('html,body').animate(
      {
        scrollTop: position - basePosition,
      },
      {
        queue: false,
      },
    );
    if (++this.scrollIndex >= $errors.length) this.scrollIndex = 0;
  }
}
