// @flow
import JQueryView, { on } from '../../../../common/JQueryView';

export default class ShowView extends JQueryView {
  constructor() {
    super('.edit_investor_corporate');
    this.changeRelationshipWithCorporationText();
  }

  @on('click #beneficial_owners_comfirm_modal_close_button')
  clickBeneficialOwnersComfirmModalCloseButton() {
    $('#beneficial_owners_comfirm_modal').hide();
  }

  @on('click #beneficial_owners_comfirm_modal_add_beneficial_owners_button')
  clickBeneficialOwnersComfirmModalAddBeneficialOwnersButton() {
    $('#beneficial_owners_comfirm_modal').hide();
  }

  @on('change input[name="investor_corporate[corporate_form]"]:radio')
  changeInvestorCorporateCorporateForm() {
    this.changeRelationshipWithCorporationText();
  }

  changeRelationshipWithCorporationText() {
    const value = $(
      'input[name="investor_corporate[corporate_form]"]:checked',
    ).val();
    const text = $('#relationship_with_corporation_text_voting_' + value).val();
    const count = $('div.beneficial-owner').length;
    for (let i = 0; i < count; i++) {
      $(
        '#investor_corporate_beneficial_owners_attributes_' +
          i +
          '_relationship_with_corporation option[value="voting"]',
      ).html(text);
    }
  }
}
