// @flow
import JQueryView, { on } from '../../../../common/JQueryView';
import { BUSINESS } from '../../../../../models/users/User';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';

declare var $: any;

export default class FormView extends JQueryView {
  edit_mode: boolean;
  notSuitable: boolean;
  scrollIndex: number;
  errorCounterActive: boolean;
  incomeResource: string;
  jobsSelectMapping: Map<number, Array<number>>;
  jobsSelectSeed: Array<any>;
  validator: any;
  constructor(edit_mode: boolean, notSuitable: boolean) {
    super('#js-user-individual-form');

    this.edit_mode = edit_mode;
    this.notSuitable = notSuitable;
    this.scrollIndex = 0;
    this.errorCounterActive = false;

    this.incomeResource = '';
    this.jobsSelectMapping = new Map($('.js-jobs-mapping').data('mapping'));
    this.jobsSelectSeed = $('.js-jobs-seed').data('seed') || [];

    this.validator = this.initJqueryValidation();
    this.initZipSerach();

    this.initForEditMode();
    this.render();
    this.validForEditMode();

    this.onLoadOpenModal();
  }

  initZipSerach() {
    $('#user_zip_code').jpostal({
      postcode: ['#user_zip_code'],
      address: {
        '#user_prefecture_id': '%3',
        '#user_city': '%4',
        '#user_address1': '%5',
      },
      trigger: {
        '#user_prefecture_id': true,
        '#user_city': true,
        '#user_address1': true,
      },
    });
  }

  initForEditMode() {
    if (!this.edit_mode) return;

    this.errorCounterActive = true;

    // 住所の折りたたみを展開
    $('.js-info-group-addr').show();
    // 携帯電話番号をお持ちでない方はチェック 固定電話の登録があるならばチェック
    $('.js-mobile').prop('checked', !!$('#user_phone').val());
  }

  validForEditMode() {
    if (!this.edit_mode) return;

    // 全体バリデーション
    this.validator.elements().each((_, e) => {
      this.validator.element(e);
    });
  }

  onLoadOpenModal() {
    if (this.notSuitable) {
      $('#nonconformModal').modal('show');
    } else if (!$('#modal_agreements').data('accepted')) {
      $('#modal_agreements').modal('show');
    }
  }

  @on('change #user_prefecture_id,#user_city,#user_address1')
  validZipCodeAutoComplete(e: any) {
    const val = $(e.currentTarget).val();
    if (val && val !== '') this.validator.element(e.currentTarget);
  }

  @on('focusin #user_zip_code')
  onFocusinUserZipCode() {
    $('.js-info-group-addr').show();
  }

  @on('change #user_address2')
  onChangeUserAddress2() {
    this.render();
  }

  @on('change #user_job')
  onChangeJob() {
    this.render();
  }

  @on('change #user_business')
  onChangeBusiness() {
    this.render();
  }

  @on('keyup #user_first_name_kana, #user_last_name_kana')
  onKeyupFirstNameKana() {
    $('#user_bank_account_name').val(
      $('#user_last_name_kana').val() + $('#user_first_name_kana').val(),
    );
  }

  @on('blur #user_zip_code')
  onBlurZipCode() {
    const original_zip_code = $('#user_zip_code').val();
    if (original_zip_code.length === 0) {
      return;
    }

    let formatted_zip_code = original_zip_code
      .replace('ー', '-')
      .replace('－', '-')
      .replace('−', '-')
      .replace('ｰ', '-')
      .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

    if (/^\d*$/.test(formatted_zip_code) === true) {
      if (formatted_zip_code.length >= 4) {
        formatted_zip_code =
          formatted_zip_code.substr(0, 3) + '-' + formatted_zip_code.substr(3);
      }
    }

    // change()を呼び出すことで最終的に住所の自動入力が走ります
    if (formatted_zip_code !== original_zip_code) {
      $('#user_zip_code')
        .val(formatted_zip_code)
        .change();
    }
  }

  @on('change #user_investor_interview_assets')
  onChangeAssets() {
    this.render();
  }

  @on('change #user_investor_interview_capital_character')
  onChangeCapitalCharacter() {
    this.render();
  }

  @on('change #user_investor_interview_purpose')
  onChangePurpose() {
    this.render();
  }

  @on('change #user_investor_interview_income_resource')
  onChangeIncomeResource() {
    this.render();
  }

  @on('change input[name="user[investor_interview][has_experience]"]:radio')
  onChangeHasExperience() {
    this.render();
  }

  @on('change .js-mobile')
  togglePhone() {
    // refresh error
    $('#user_phone_mobile').removeClass('validation-error error');
    $('#user_phone_mobile-error').hide();

    this.render();
  }

  @on('click input[type="submit"]')
  onSubmitActivateErrorCounter() {
    this.errorCounterActive = true;
  }

  @on('keydown ')
  onkeydownElementPreventSubmit(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault(); // Enterキーのデフォルト動作を防ぐ
    }
  }

  render() {
    // interview
    const notExpChecked = $(
      '#user_investor_interview_has_experience_false',
    ).prop('checked');
    $('.js-has-experience-error').toggle(notExpChecked);

    const assets = $('#user_investor_interview_assets').val();
    $('.js-financial-error').toggle(assets === '0');

    const cc = $('#user_investor_interview_capital_character').val();
    $('.js-capital-character-toggle').toggle(cc === '7');
    $('.js-personality-error').toggle(['1', '3', '8'].includes(cc));

    const purpose = $('#user_investor_interview_purpose').val();
    $('.js-purpose-toggle').toggle(purpose === '5');

    const $ir = $('#user_investor_interview_income_resource');
    const ir = $ir.val();
    $('.js-income-resource-toggle').toggle(ir === '3');

    const userBusiness = parseInt($('#user_business').val());
    $('.js-business-toggle').toggle(userBusiness === BUSINESS.others);

    // 固定電話入力時: アコーディオン展開、携帯電話入力不可
    const accordionPhone = $('.js-mobile').prop('checked');
    $('.fixed-tell-wrap').toggle(accordionPhone);
    $('#user_phone_mobile').prop('disabled', accordionPhone);

    if (accordionPhone) {
      $('#user_phone_mobile').val('');
    } else {
      $('#user_phone').val('');
    }

    const hasExperience = $(
      'input[name="user[investor_interview][has_experience]"]',
    )
      .filter(':checked')
      .val();
    $('#experience').toggle(hasExperience === 'true');

    const $job = $('#user_job');

    // 収入源によって、職業を絞り込む
    if (this.incomeResource !== ir) {
      const activeSelectIndex = this.jobsSelectMapping.get(Number(ir)) || [];
      let activeSelect;

      // 変更され時のみ実行するために、現在の値を保持しておく
      this.incomeResource = ir;

      if (activeSelectIndex.length > 0) {
        activeSelect = this.jobsSelectSeed.filter(([, index]) =>
          activeSelectIndex.includes(index),
        );
      } else {
        // 絞り込みがないならば全部返す
        activeSelect = this.jobsSelectSeed;
      }

      if (activeSelect.length > 0) {
        let options = [
          $('<option>')
            .val('')
            .text('選択してください'),
          ...activeSelect.map(([text, val]) =>
            $('<option>')
              .val(val)
              .text(text),
          ),
        ];
        const selctedJobVal = $job.val();

        // 書き換え後に選択状態を再反映する
        $job
          .empty()
          .append(options)
          .val(selctedJobVal);
        // 選択中の項目が消えた場合は、未入力にリセット
        if (!$job.val()) $job.val('');
      }
    }

    const userJob = parseInt($job.val());
    $('.js-job-toggle').toggle(userJob == 17);

    const $jobAccordion = $('.js-job-employed-accordion');
    const unemployed = [44, 45, 46];
    // 無職ならば入力欄を非表示
    if (!isNaN(userJob) && !unemployed.includes(userJob)) {
      $jobAccordion.show();
    } else {
      $jobAccordion.hide();
    }

    // users
    if ($('#user_zip_code').val() !== '' && $('#user_address2').val() === '') {
      $('#address2-notice').show();
      $('#user_address2').addClass('address-em');
    } else {
      $('#address2-notice').hide();
      $('#user_address2').removeClass('address-em');
    }

    if (['22', '23', '24'].includes(userJob)) {
      $('.user_business').hide();
    } else {
      $('.user_business').show();
    }

    this.fetchErrors();
  }

  initJqueryValidation() {
    const validator = $('#js-user-individual-form').validate({
      errorClass: 'validation-error error',
      rules: {
        'user[investor_interview][income_resource_other]': {
          required: {
            depends: () => {
              return (
                $('#user_investor_interview_income_resource').val() === '3'
              );
            },
          },
        },
        'user[investor_interview][capital_character_note]': {
          required: {
            depends: () => {
              return (
                $('#user_investor_interview_capital_character').val() === '7'
              );
            },
          },
        },
        'user[investor_interview][purpose_note]': {
          required: {
            depends: () => {
              return $('#user_investor_interview_purpose').val() === '5';
            },
          },
        },
        'user[last_name_kana]': {
          kana: true,
        },
        'user[first_name_kana]': {
          kana: true,
        },
        'user[phone]': {
          require_from_group: [1, '.js-validate-group-phone'],
          number: true,
          rangelength: [10, 10],
        },
        'user[phone_mobile]': {
          require_from_group: [1, '.js-validate-group-phone'],
          number: true,
          rangelength: [11, 11],
        },
        'user[zip_code]': {
          zip_code: true,
        },
        'user[edit_address_miss_confirmed]': {
          required: true,
        },
        'user[job_details]': {
          required: {
            depends: () => {
              return $('#user_job').val() == 17;
            },
          },
        },
        'user[business_details]': {
          required: {
            depends: () => {
              return $('#user_business').val() == 9;
            },
          },
        },
        'user[phone_workplace]': {
          number: true,
          rangelength: [10, 11],
        },
        'user[bank_account_number]': {
          number: true,
          rangelength: [7, 7],
        },
        'user[bank_account_name]': {
          kana: true,
        },
      },
      messages: {
        'user[investor_interview][has_experience]': {
          required: 'サービスをご利用いただけません',
        },
        'user[investor_interview][exp_stock]': {
          required: '投資経験の選択は必須です',
        },
        'user[investor_interview][exp_bond]': {
          required: '投資経験の選択は必須です',
        },
        'user[investor_interview][exp_trust_fund]': {
          required: '投資経験の選択は必須です',
        },
        'user[investor_interview][exp_commodity_futures]': {
          required: '投資経験の選択は必須です',
        },
        'user[investor_interview][exp_foreign_currency_saving]': {
          required: '投資経験の選択は必須です',
        },
        'user[investor_interview][exp_foreign_exchange]': {
          required: '投資経験の選択は必須です',
        },
        'user[investor_interview][exp_account]': {
          required: '投資経験の選択は必須です',
        },
        'user[investor_interview][income_resource]': {
          required: '主な収入源を選択してください',
        },
        'user[investor_interview][income_resource_other]': {
          required: 'その他の場合は詳細を入力してください',
        },
        'user[investor_interview][income]': {
          required: '年収を選択してください',
        },
        'user[investor_interview][assets]': {
          required: '金融資産を選択してください',
        },
        'user[investor_interview][capital_character]': {
          required: '投資資金の性格を選択してください',
        },
        'user[investor_interview][capital_character_note]': {
          required: 'その他の場合は詳細を入力してください',
        },
        'user[investor_interview][purpose]': {
          required: '投資の目的を選択してください',
        },
        'user[investor_interview][purpose_note]': {
          required: 'その他の場合は詳細を入力してください',
        },
        'user[last_name]': {
          required: '性を入力してください',
        },
        'user[first_name]': {
          required: '名を入力してください',
        },
        'user[last_name_kana]': {
          required: 'セイを入力してください',
        },
        'user[first_name_kana]': {
          required: 'メイを入力してください',
        },
        'user[birth_on(1i)]': {
          required: '生年月日を選択してください',
        },
        'user[birth_on(2i)]': {
          required: '生年月日を選択してください',
        },
        'user[birth_on(3i)]': {
          required: '生年月日を選択してください',
        },
        'user[gender]': {
          required: '性別を選択してください',
        },
        'user[zip_code]': {
          required: '郵便番号を入力してください',
        },
        'user[prefecture_id]': {
          required: '都道府県を選択してください',
        },
        'user[city]': {
          required: '市区町村を入力してください',
        },
        'user[address1]': {
          required: '町域を入力してください',
        },
        'user[address2]': {
          required: '地番・マンション名・部屋番号を入力してください',
        },
        'user[edit_address_miss_confirmed]': {
          required: '受諾してください',
        },
        'user[phone]': {
          required: '固定電話番号を入力してください',
          require_from_group: '固定電話番号を入力してください',
          number: '固定電話番号を正しく入力してください',
          rangelength: '固定電話番号を正しく入力してください',
        },
        'user[phone_mobile]': {
          required: '携帯電話番号を入力してください',
          require_from_group: '携帯電話番号を入力してください',
          number: '携帯電話番号を正しく入力してください',
          rangelength: '携帯電話番号を正しく入力してください',
        },
        'user[job]': {
          required: '職業を選択してください',
        },
        'user[job_details]': {
          required: 'その他の場合は詳細を入力してください',
        },
        'user[business]': {
          required: '業種を選択してください',
        },
        'user[business_details]': {
          required: 'その他の場合は詳細を入力してください',
        },
        'user[workplace]': {
          required: '勤務先会社名を入力してください',
        },
        'user[phone_workplace]': {
          required: '勤務先電話番号を入力してください',
          number: '勤務先電話番号を正しく入力してください',
          rangelength: '勤務先電話番号を正しく入力してください',
        },
        'user[bank_account_type]': {
          required: '預金科目を選択してください',
        },
        'user[bank_account_number]': {
          required: '口座番号を入力してください',
          number: '口座番号を正しく入力してください',
          rangelength: '口座番号を正しく入力してください',
        },
        'user[bank_account_name]': {
          required: '口座名義（カナ）を入力してください',
          kana: '口座名義を正しく入力してください',
        },
      },
      groups: {
        birth_on: [
          'user[birth_on(1i)]',
          'user[birth_on(2i)]',
          'user[birth_on(3i)]',
        ].join(' '),
        has_exp: [
          'user[investor_interview][exp_stock]',
          'user[investor_interview][exp_bond]',
          'user[investor_interview][exp_trust_fund]',
          'user[investor_interview][exp_commodity_futures]',
          'user[investor_interview][exp_foreign_currency_saving]',
          'user[investor_interview][exp_foreign_exchange]',
          'user[investor_interview][exp_account]',
        ].join(' '),
      },
      errorPlacement: (error, element) => {
        error.insertAfter(element.closest('.form-group'));
      },
      highlight: (element, errorClass, validClass) => {
        if (validator.checkable(element)) {
          $(`input[name="${element.name}"]`)
            .closest('.form-check')
            .addClass(errorClass)
            .removeClass(validClass);
        } else {
          $(element)
            .addClass(errorClass)
            .removeClass(validClass);
        }
      },
      unhighlight: (element, errorClass, validClass) => {
        if (validator.checkable(element)) {
          $(`input[name="${element.name}"]`)
            .closest('.form-check')
            .removeClass(errorClass)
            .addClass(validClass);
        } else {
          $(element)
            .removeClass(errorClass)
            .addClass(validClass);
        }
      },
      onfocusout: function(element) {
        $(element).valid();
      },
      onkeyup: function(element, event) {
        // サーバ側のエラーを非表示
        $(element)
          .closest('.form-group')
          .find('label.validation-error')
          .hide();

        // https://github.com/jquery-validation/jquery-validation/blob/b4f8d54633adb224457790d2bd48673d0cd984b4/src/core.js#L297
        const excludedKeys = [
          16,
          17,
          18,
          20,
          35,
          36,
          37,
          38,
          39,
          40,
          45,
          144,
          225,
        ];

        if (
          (event.which === 9 && this.elementValue(element) === '') ||
          $.inArray(event.keyCode, excludedKeys) !== -1
        ) {
          return;
        }

        this.element(element);
      },
      onclick: function(element) {
        // サーバ側のエラーを非表示
        $(element)
          .closest('.form-group')
          .find('label.validation-error')
          .hide();

        if (this.groups[element.name] === 'has_exp')
          $('.has-experience-error').hide();

        if (element.name in this.submitted || this.checkable(element)) {
          this.element(element);
        } else if (element.parentNode.name in this.submitted) {
          this.element(element.parentNode);
        }
      },
      showErrors: function() {
        this.defaultShowErrors();
        this.settings.fetchErrors();
      },
      fetchErrors: () => {
        this.fetchErrors();
      },
      focusInvalid: false,
      ignore: ':hidden, :disabled',
    });

    $.validator.addMethod(
      'kana',
      (value, element) => {
        return validator.optional(element) || /^([ァ-ンー]+)$/.test(value);
      },
      'カナに使用できない文字が含まれています',
    );

    $.validator.addMethod(
      'zip_code',
      (value, element) => {
        return validator.optional(element) || /^\d{3}-?\d{4}$/.test(value);
      },
      '郵便番号を正しく入力してください',
    );

    return validator;
  }

  @on('change select')
  onChangeSelectValidation(e: any) {
    // サーバ側のエラーを非表示
    if (e.currentTarget.name.match(/birth_on/)) {
      $('.birth-on-error').hide();
      $('.js-customer-birthday-select-wrap').removeClass('is-invalid');
    }

    this.validator.element(e.currentTarget);
  }

  fetchErrors() {
    if (!this.errorCounterActive) return;

    const num = $('label.validation-error:visible').length;

    if (num > 0) {
      $('.error-counter-text.error-count').html(num);
      $('.js-error-counter').fadeIn();
    } else {
      $('.js-error-counter').fadeOut();
    }

    if (this.scrollIndex >= num) this.scrollIndex = 0;
  }

  @on('click .error-counter')
  scrollNextError(e: any) {
    const $errors = $('label.validation-error:visible');

    if ($errors.length === 0) return;

    const basePosition = e.currentTarget.offsetTop;
    const position = $($errors[this.scrollIndex]).offset().top;

    $('html,body').animate(
      {
        scrollTop: position - basePosition,
      },
      {
        queue: false,
      },
    );
    if (++this.scrollIndex >= $errors.length) this.scrollIndex = 0;
  }

  @on('click .collection_radio_buttons')
  onClickRadioButton(e: any) {
    $(e.currentTarget)
      .closest('.form-group')
      .addClass('is-valid');
  }
}
