// @flow
import JQueryView, { on } from '../common/JQueryView';

export default class FormView extends JQueryView {
  others: boolean;

  constructor() {
    super('.simple_form');

    // 初期表示クラス
    $('input, select, textarea').each(function() {
      const $el = $(this);
      if ($el.val() !== '') {
        $el.addClass('is-activated');
      }
    });
  }

  @on('change input,select,textarea')
  onChangeInputStyle(e: any) {
    const $el = $(e.currentTarget);

    if ($el.val() === '') {
      $el.removeClass('is-activated');
    } else {
      $el.addClass('is-activated').removeClass('is-invalid');

      $el
        .parents('.form-group')
        .find('.invalid-feedback')
        .hide();
    }
  }

  @on('change .js-contact-file')
  onChangeContactFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      $('.file-name')
        .show()
        .text(file.name);
      $('.file-delete').show();
      $('.free_contact_filename').addClass('free_contact_filesend');
    }
  }

  @on('click .js-concat-file-delete')
  onClickContactFileDelete() {
    // ファイルの選択をリセット
    $('.js-contact-file').val('');

    $('.file-name').hide();
    $('.file-delete').hide();
    $('.free_contact_filename').removeClass('free_contact_filesend');
  }
}
