// @flow
import JQueryView, { on } from '../../../common/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  application: string;
  certificateOfHistory: string;
  certificateOfStamp: string;

  constructor(
    application: string,
    certificateOfHistory: string,
    certificateOfStamp: string,
  ) {
    super('#form_view');

    this.application = application;
    this.certificateOfHistory = certificateOfHistory;
    this.certificateOfStamp = certificateOfStamp;

    $(document).ready(() => {
      this.render();
    });
  }

  @on('change #investor_corporate_document_application')
  onChangeApplication(event: any) {
    if (!event.target.files.length) {
      return;
    }

    const file = $(event.target).prop('files')[0];

    if (file.type === 'application/pdf') {
      this.application = file.name;
      this.render();

      $('#investor_corporate_document_remove_application').val('');
    }
  }

  @on('change #investor_corporate_document_certificate_of_history')
  onChangeCertificateOfHistory(event: any) {
    if (!event.target.files.length) {
      return;
    }

    if (event.target.files.length > 5) {
      alert('アップロードできるファイルは5ファイルまでです');
      $('#investor_corporate_document_certificate_of_history').val(''); // ULされたファイルをクリア
      return;
    }

    const files = Array.from(event.target.files);
    const fileTypeArray = [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
    ];

    if (files.every(file => fileTypeArray.includes(file.type))) {
      // 削除フラグをクリアし再設定
      $('#investor_corporate_document_remove_certificate_of_history').val('');
      $('#investor_corporate_document_remove_certificate_of_history_2').val('');
      $('#investor_corporate_document_remove_certificate_of_history_3').val('');
      $('#investor_corporate_document_remove_certificate_of_history_4').val('');
      $('#investor_corporate_document_remove_certificate_of_history_5').val('');
      for (let i = event.target.files.length + 1; i <= 5; i++) {
        $(
          '#investor_corporate_document_remove_certificate_of_history_' + i,
        ).val('1');
      }

      this.certificateOfHistory = files.map(file => file.name).join(', ');
      this.render();
    }
  }

  @on('change #investor_corporate_document_certificate_of_stamp')
  onChangeCertificateOfStamp(event: any) {
    if (!event.target.files.length) {
      return;
    }

    if (event.target.files.length > 5) {
      alert('アップロードできるファイルは5ファイルまでです');
      $('#investor_corporate_document_certificate_of_stamp').val(''); // ULされたファイルをクリア
      return;
    }

    const files = Array.from(event.target.files);
    const fileTypeArray = [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
    ];

    if (files.every(file => fileTypeArray.includes(file.type))) {
      // 削除フラグをクリアし再設定
      $('#investor_corporate_document_remove_certificate_of_stamp').val('');
      $('#investor_corporate_document_remove_certificate_of_stamp_2').val('');
      $('#investor_corporate_document_remove_certificate_of_stamp_3').val('');
      $('#investor_corporate_document_remove_certificate_of_stamp_4').val('');
      $('#investor_corporate_document_remove_certificate_of_stamp_5').val('');
      for (let i = event.target.files.length + 1; i <= 5; i++) {
        $('#investor_corporate_document_remove_certificate_of_stamp_' + i).val(
          '1',
        );
      }

      this.certificateOfStamp = files.map(file => file.name).join(', ');
      this.render();
    }
  }

  @on('click #remove_application_button')
  onClickRemoveApplication() {
    $('#investor_corporate_document_application').val('');
    this.application = '';
    $('#remove_application_button').hide();
    $('#investor_corporate_document_remove_application').val('1');
    this.render();
  }

  @on('click #remove_certificate_of_history_button')
  onClickRemoveCertificateOfHistory() {
    $('#investor_corporate_document_certificate_of_history').val('');
    this.certificateOfHistory = '';
    $('#remove_certificate_of_history_button').hide();
    $('#investor_corporate_document_remove_certificate_of_history').val('1');
    $('#investor_corporate_document_remove_certificate_of_history_2').val('1');
    $('#investor_corporate_document_remove_certificate_of_history_3').val('1');
    $('#investor_corporate_document_remove_certificate_of_history_4').val('1');
    $('#investor_corporate_document_remove_certificate_of_history_5').val('1');
    this.render();
  }

  @on('click #remove_certificate_of_stamp_button')
  onClickRemoveCertificateOfStamp() {
    $('#investor_corporate_document_certificate_of_stamp').val('');
    this.certificateOfStamp = '';
    $('#remove_certificate_of_stamp_button').hide();
    $('#investor_corporate_document_remove_certificate_of_stamp').val('1');
    $('#investor_corporate_document_remove_certificate_of_stamp_2').val('1');
    $('#investor_corporate_document_remove_certificate_of_stamp_3').val('1');
    $('#investor_corporate_document_remove_certificate_of_stamp_4').val('1');
    $('#investor_corporate_document_remove_certificate_of_stamp_5').val('1');
    this.render();
  }

  render() {
    if (this.application === '') {
      $('#preview_application')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_application_button').hide();
    } else {
      $('#preview_application')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.application);
      $('#remove_application_button').show();
    }

    if (this.certificateOfHistory === '') {
      $('#preview_certificate_of_history')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_certificate_of_history_button').hide();
    } else {
      $('#preview_certificate_of_history')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.certificateOfHistory);
      $('#remove_certificate_of_history_button').show();
    }

    if (this.certificateOfStamp === '') {
      $('#preview_certificate_of_stamp')
        .empty()
        .css('height', 'auto')
        .addClass('file-empty')
        .append('ファイルが選択されていません');
      $('#remove_certificate_of_stamp_button').hide();
    } else {
      $('#preview_certificate_of_stamp')
        .empty()
        .css('height', 'auto')
        .removeClass('file-empty')
        .append(this.certificateOfStamp);
      $('#remove_certificate_of_stamp_button').show();
    }
  }
}
