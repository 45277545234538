// @flow
import React from 'react';
import { delimited } from '../../helpers/ApplicationHelper';
import IncomeModal from './IncomeModal';
import AngelTaxResultView from './AngelTaxResultView';
import type { AngelTax } from './AngelTaxResultView';

type Props = {
  taxReturnImage: string,
  taxWithholdingImage: string,
};

type State = {
  income: string,
  incomeError: ?string,
  investment: string,
  investmentError: ?string,
  gain: string,
  gainError: ?string,
  gainByOutsideStock: string,
  gainByOutsideStockError: ?string,
  showIncomeModal: boolean,
  showResult: boolean,
  incomeTax: ?number,
  angelTaxA: ?AngelTax,
  angelTaxB: ?AngelTax,
};

// 株式譲渡益に対する税率(復興特別所得税含む)
const S_TAX = 0.15315;
// 復興特別所得税率
const TAX_FOR_RECONSTRUCTION = 0.021;

export default class AngelTaxView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      income: '',
      incomeError: null,
      investment: '',
      investmentError: null,
      gain: '0',
      gainError: null,
      gainByOutsideStock: '0',
      gainByOutsideStockError: null,
      showIncomeModal: false,
      showResult: false,
      incomeTax: null,
      angelTaxA: null,
      angelTaxB: null,
    };
  }

  static lookUpTaxPercentage(income: number): number {
    if (income <= 1950000) {
      return 0.05;
    } else if (1950000 < income && income <= 3300000) {
      return 0.1;
    } else if (3300000 < income && income <= 6950000) {
      return 0.2;
    } else if (6950000 < income && income <= 9000000) {
      return 0.23;
    } else if (9000000 < income && income <= 18000000) {
      return 0.33;
    } else if (18000000 < income && income <= 40000000) {
      return 0.4;
    } else {
      return 0.45;
    }
  }

  static calcDeduction(tax: number): number {
    if (tax <= 0.05) {
      return 0;
    } else if (tax === 0.1) {
      return 97500;
    } else if (tax === 0.2) {
      return 427500;
    } else if (tax === 0.23) {
      return 636000;
    } else if (tax === 0.33) {
      return 1536000;
    } else if (tax === 0.4) {
      return 2796000;
    } else if (tax === 0.45) {
      return 4796000;
    } else {
      return 0;
    }
  }

  onChangeIncome(event: any) {
    this.setState({
      income: event.target.value,
      incomeError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusIncome(event: any) {
    //先にコンマを取り除かないとincomeErrorに値が入ってしまうので、
    //先にコンマ取っておく
    let value = this.deleteComma(event.target.value);
    this.setState({
      income: value,
      incomeError: this.errorMessageOnChange(value),
    });
  }

  onBlurIncome(event: any) {
    this.setState({
      income: this.numberWithComma(event.target.value),
      incomeError: this.errorMessageOnChange(event.target.value),
    });
  }

  onChangeInvestment(event: any) {
    this.setState({
      investment: event.target.value,
      investmentError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusInvestment(event: any) {
    let value = this.deleteComma(event.target.value);
    this.setState({
      investment: value,
      investmentError: this.errorMessageOnChange(value),
    });
  }

  onBlurInvestment(event: any) {
    this.setState({
      investment: this.numberWithComma(event.target.value),
      investmentError: this.errorMessageOnChange(event.target.value),
    });
  }

  onChangeGain(event: any) {
    this.setState({
      gain: event.target.value,
      gainError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusGain(event: any) {
    let value = this.deleteComma(event.target.value);
    this.setState({
      gain: value,
      gainError: this.errorMessageOnChange(value),
    });
  }

  onBlurGain(event: any) {
    this.setState({
      gain: this.numberWithComma(event.target.value),
      gainError: this.errorMessageOnChange(event.target.value),
    });
  }

  onChangeGainByOutsideStock(event: any) {
    this.setState({
      gainByOutsideStock: event.target.value,
      gainByOutsideStockError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusGainByOutsideStock(event: any) {
    let value = this.deleteComma(event.target.value);
    this.setState({
      gainByOutsideStock: value,
      gainByOutsideStockError: this.errorMessageOnChange(value),
    });
  }

  onBlurGainByOutsideStock(event: any) {
    this.setState({
      gainByOutsideStock: this.numberWithComma(event.target.value),
      gainByOutsideStockError: this.errorMessageOnChange(event.target.value),
    });
  }

  errorMessageOnChange(value: ?string): ?string {
    if (value == null || value === '') {
      return null;
    }
    if (!value.match(/^[0-9]+$/)) {
      return '半角数字を入力してください';
    }
    if (parseInt(value) >= parseInt(1000000000000)) {
      return '1,000,000,000,000未満の値を入力してください';
    }
    return null;
  }

  //入力ボックスからフォーカスが離れたときに、コンマを付加する
  numberWithComma(value: string): string {
    if (!value.match(/^[0-9]+$/)) {
      //元々の入力値が不正の場合は、何もしないで値を返す
      return value;
    }
    return delimited(Math.floor(parseInt(value)));
  }

  deleteComma(value: string): string {
    return value.replace(/,/g, '');
  }

  integerWithoutComma(value: string): number {
    if (value == '') {
      return 0;
    }
    return parseInt(this.deleteComma(value));
  }

  calcIncomeTax(): ?number {
    if (
      this.state.income === '' ||
      this.state.incomeError != null ||
      this.state.gainError != null ||
      this.state.gainByOutsideStockError != null
    ) {
      return null;
    }

    const income = this.integerWithoutComma(this.state.income);
    const gainTotal =
      this.integerWithoutComma(this.state.gain) +
      this.integerWithoutComma(this.state.gainByOutsideStock);
    const taxPercentage = AngelTaxView.lookUpTaxPercentage(
      income - this.baseDeductionByIncome(income),
    );
    const deduction = AngelTaxView.calcDeduction(taxPercentage);
    const taxAfterDeduction = Math.max(
      this.roundForTax(
        (income - this.baseDeductionByIncome(income)) * taxPercentage -
          deduction,
      ),
      0,
    );
    // 戻り値に復興特別所得税を加える
    const wkAmount = taxAfterDeduction * (TAX_FOR_RECONSTRUCTION * 1000);
    const reconstructionTax = wkAmount / 1000;

    return Math.max(
      taxAfterDeduction +
        this.roundForTax((gainTotal - Math.max(480000 - income, 0)) * S_TAX) +
        reconstructionTax,
      0,
    );
  }

  calcAngelTaxA(incomeTax: ?number): ?AngelTax {
    //investment, gain, gainByOutsideStockは空でもOKに変更した。
    //初期値に0を設定しないようにしたため。
    if (
      incomeTax == null ||
      this.state.income === '' ||
      this.state.incomeError != null ||
      this.state.investmentError != null ||
      this.state.gainError != null ||
      this.state.gainByOutsideStockError != null
    ) {
      return null;
    }

    const income = this.integerWithoutComma(this.state.income);
    const investment = this.integerWithoutComma(this.state.investment);
    const gainTotal =
      this.integerWithoutComma(this.state.gain) +
      this.integerWithoutComma(this.state.gainByOutsideStock);
    const baseDeduction = this.baseDeductionByIncome(income);
    // 基礎控除のみ引いて判定用
    const incomeExceptBaseDeduction = Math.max(income - baseDeduction, 0);
    const deductionA = Math.max(
      Math.min(investment, 8000000, income * 0.4) - 2000,
      0,
    );
    // 基礎控除＆優遇措置Aを引いて判定用
    const incomeExceptDeductionAandBase = Math.max(
      incomeExceptBaseDeduction - deductionA,
      0,
    );
    // 税率
    const taxPercentage = AngelTaxView.lookUpTaxPercentage(
      incomeExceptDeductionAandBase,
    );
    const deduction = AngelTaxView.calcDeduction(taxPercentage);
    // 譲渡益を除いた所得税(復興税未加算)
    const incomeTaxExceptGailTotal =
      this.roundForTax(incomeExceptDeductionAandBase * taxPercentage) -
      deduction;
    const incomeTaxA =
      incomeTaxExceptGailTotal +
      this.roundForTax((gainTotal - Math.max(480000 - income, 0)) * S_TAX);

    const reconstructionTax = Math.floor(
      incomeTaxExceptGailTotal * TAX_FOR_RECONSTRUCTION,
    );

    return {
      deduction: deductionA,
      incomeTax: incomeTaxA + reconstructionTax,
      refund: Math.max(incomeTax - (incomeTaxA + reconstructionTax), 0),
    };
  }

  calcAngelTaxB(incomeTax: ?number): ?AngelTax {
    //investment, gain, gainByOutsideStockは空でもOKに変更した。
    //初期値に0を設定しないようにしたため。
    if (
      incomeTax == null ||
      this.state.income === '' ||
      this.state.incomeError != null ||
      this.state.investmentError != null ||
      this.state.gainError != null ||
      this.state.gainByOutsideStockError != null
    ) {
      return null;
    }

    const income = this.integerWithoutComma(this.state.income);
    const investment = this.integerWithoutComma(this.state.investment);
    const gainTotal =
      this.integerWithoutComma(this.state.gain) +
      this.integerWithoutComma(this.state.gainByOutsideStock);
    const baseDeduction = this.baseDeductionByIncome(income);
    // 基礎控除のみ引いて判定用
    const incomeExceptBaseDeduction = Math.max(income - baseDeduction, 0);
    // 税率
    const taxPercentage = AngelTaxView.lookUpTaxPercentage(
      incomeExceptBaseDeduction,
    );
    const deduction = AngelTaxView.calcDeduction(taxPercentage);
    const deductionB = Math.min(
      Math.min(investment, gainTotal) +
        (income <= 480000 ? 480000 - income : 0),
      gainTotal,
    );
    // 譲渡益を除いた所得税(復興税未加算)
    const incomeTaxExceptGailTotal =
      this.roundForTax(incomeExceptBaseDeduction * taxPercentage) - deduction;

    const reconstructionTax = Math.floor(
      incomeTaxExceptGailTotal * TAX_FOR_RECONSTRUCTION,
    );

    const incomeTaxB =
      incomeTaxExceptGailTotal +
      this.roundForTax(
        Math.max(gainTotal - investment - Math.max(480000 - income, 0), 0) *
          S_TAX,
      ) +
      reconstructionTax;

    return {
      deduction: deductionB,
      incomeTax: incomeTaxB,
      refund: Math.max(incomeTax - incomeTaxB, 0),
    };
  }

  roundForTax(sourceNumber: ?number): number {
    if (sourceNumber == null) {
      return 0;
    }
    return Math.floor(sourceNumber / 100) * 100;
  }

  baseDeductionByIncome(income: ?number): number {
    if (income != null) {
      if (income <= 24000000) {
        return 480000;
      } else if (income <= 24500000) {
        return 320000;
      } else if (income <= 25000000) {
        return 160000;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  handleSubmit(): void {
    const incomeTax = this.calcIncomeTax();

    this.setState({
      showResult: true,
      incomeTax: incomeTax,
      angelTaxA: this.calcAngelTaxA(incomeTax),
      angelTaxB: this.calcAngelTaxB(incomeTax),
    });
  }

  render() {
    const { taxReturnImage, taxWithholdingImage } = this.props;
    const {
      income,
      incomeError,
      investment,
      investmentError,
      gain,
      gainError,
      gainByOutsideStock,
      gainByOutsideStockError,
      showIncomeModal,
      showResult,
      incomeTax,
      angelTaxA,
      angelTaxB,
    } = this.state;

    return (
      <div className="angel-main-wrap">
        <h1 className="angel-title">エンジェル税制シミュレーター</h1>
        <div className="alert alert-primary alert-dismissible angel-alert-text">
          <b>所得税の計算について</b>
          <ul>
            <li>
              令和4年6月時点の税制を基にした概算のシミュレーションとなります。
            </li>
            <li>
              所得控除は基礎控除のみで、その他控除については考慮していません。
            </li>
            <li>所得税額には、復興特別所得税額(2.1%)が含まれております。</li>
            <li>
              株式譲渡は分離課税選択で税率は15.315%(所得税および復興特別所得税)です。
            </li>
            <li>
              基礎控除の計算については、総所得金額と合計所得金額が同一である前提で行っております。
            </li>
          </ul>
        </div>
        <div>
          <form className="top-form">
            <div
              className={
                incomeError != null
                  ? 'angel-form-input-block form-group income has-error'
                  : 'angel-form-input-block form-group income'
              }
            >
              <div
                className="control-label sp-none required-label income-label"
                htmlFor="income"
              >
                総所得金額
                <div
                  className="question-icon"
                  onClick={() => this.setState({ showIncomeModal: true })}
                ></div>
              </div>

              <div className="angel-form-input-wrap">
                <div className="angel-form-input-inr">
                  <input
                    id="income"
                    type="text"
                    inputMode="numeric"
                    className={
                      income !== '' && incomeError === null
                        ? 'form-control required is-valid'
                        : 'form-control required'
                    }
                    value={income}
                    onChange={e => this.onChangeIncome(e)}
                    onFocus={e => this.onFocusIncome(e)}
                    onBlur={e => this.onBlurIncome(e)}
                    placeholder="例, 10000000"
                  />
                  <span>円</span>
                </div>
                {incomeError && (
                  <span className="help-block error-message">
                    {incomeError}
                  </span>
                )}
              </div>
            </div>
            <div
              className={
                investmentError != null
                  ? 'angel-form-input-block form-group investment has-error'
                  : 'angel-form-input-block form-group investment'
              }
            >
              <div
                className="control-label required-label"
                htmlFor="investment"
              >
                エンジェル税制適用会社への投資額
              </div>
              <div className="angel-form-input-wrap">
                <div className="angel-form-input-inr">
                  <input
                    id="investment"
                    type="text"
                    inputMode="numeric"
                    className={
                      investment !== '' && investmentError === null
                        ? 'form-control required is-valid'
                        : 'form-control required'
                    }
                    value={investment}
                    onChange={e => this.onChangeInvestment(e)}
                    onFocus={e => this.onFocusInvestment(e)}
                    onBlur={e => this.onBlurInvestment(e)}
                    placeholder="例, 500000"
                  />
                  <span>円</span>
                </div>
                {investmentError && (
                  <span className="help-block error-message">
                    {investmentError}
                  </span>
                )}
              </div>
            </div>
            <div
              className={
                gainError != null
                  ? 'angel-form-input-block form-group gain has-error'
                  : 'angel-form-input-block form-group gain'
              }
            >
              <div className="control-label optional-label" htmlFor="gain">
                上場株式の売買による譲渡益
              </div>
              <div className="angel-form-input-wrap">
                <div className="angel-form-input-inr">
                  <input
                    id="gain"
                    type="text"
                    inputMode="numeric"
                    className="form-control"
                    value={gain}
                    onChange={e => this.onChangeGain(e)}
                    onFocus={e => this.onFocusGain(e)}
                    onBlur={e => this.onBlurGain(e)}
                    placeholder="例, 1000000"
                  />
                  <span>円</span>
                </div>
                {gainError && (
                  <span className="help-block error-message">{gainError}</span>
                )}
                <p className="help-block pt-2 font-weight-normal">
                  ※上場株式の売買において譲渡損の場合は0を記入してください。
                  <br />
                  ※上場株式に関わる譲渡損失の繰越がない前提で記入してください。
                  <br />
                  ※株式譲渡は特定口座（源泉あり）で取引されていることが前提です。
                </p>
              </div>
            </div>
            <div
              className={
                gainByOutsideStockError != null
                  ? 'angel-form-input-block form-group gain-by-outside-stock has-error'
                  : 'angel-form-input-block form-group gain-by-outside-stock'
              }
            >
              <div
                className="control-label optional-label"
                htmlFor="gain-by-outside-stock"
              >
                非上場株式の売買による譲渡益
              </div>
              <div className="angel-form-input-wrap">
                <div className="angel-form-input-inr">
                  <input
                    id="gain-by-outside-stock"
                    type="text"
                    inputMode="numeric"
                    className="form-control"
                    value={gainByOutsideStock}
                    onChange={e => this.onChangeGainByOutsideStock(e)}
                    onFocus={e => this.onFocusGainByOutsideStock(e)}
                    onBlur={e => this.onBlurGainByOutsideStock(e)}
                    placeholder="例, 1000000"
                  />
                  <span>円</span>
                </div>
                {gainByOutsideStockError && (
                  <span className="help-block error-message">
                    {gainByOutsideStockError}
                  </span>
                )}
                <p className="help-block pt-2 font-weight-normal">
                  ※非上場株式の売買において譲渡損の場合は0を記入してください。
                  <br />
                  ※非上場株式に関わる譲渡損失の繰越がない前提で記入してください。
                </p>
              </div>
            </div>
          </form>

          <div
            className={
              showResult
                ? 'calculation-submit recalculate'
                : 'calculation-submit'
            }
            onClick={() => this.handleSubmit()}
          >
            <a>{showResult ? '再計算する' : '計算する'}</a>
          </div>
        </div>

        <AngelTaxResultView
          showResult={showResult}
          incomeTax={incomeTax}
          angelTaxA={angelTaxA}
          angelTaxB={angelTaxB}
        />

        <IncomeModal
          show={showIncomeModal}
          taxReturnImage={taxReturnImage}
          taxWithholdingImage={taxWithholdingImage}
          onClose={() => this.setState({ showIncomeModal: false })}
        />
      </div>
    );
  }
}
