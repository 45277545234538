// @flow
import React from 'react';
import { delimited } from '../../helpers/ApplicationHelper';
import BlueArrowPng from '../../../../assets/images/views/questions/blue-arrow.png';
import GreenArrowPng from '../../../../assets/images/views/questions/green-arrow.png';

type Props = {
  showResult: boolean,
  incomeTax: ?number,
  angelTaxA: ?AngelTax,
  angelTaxB: ?AngelTax,
};

export type AngelTax = {
  deduction: number,
  incomeTax: number,
  refund: number,
};

export default class AngelTaxResultView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { showResult, incomeTax, angelTaxA, angelTaxB } = this.props;

    if (!showResult) return '';

    return (
      <div className="angel-pattern-wrap js-cal-block">
        <h2 className="angel-pattern-title">シミュレーション結果</h2>
        <div className="angel-form-input-block income-tax-border">
          <label className="control-label yellow-text-top" htmlFor="gain">
            エンジェル税制
            <div className="yellow-text">未使用の場合の所得税額</div>
          </label>
          <div className="value">
            {incomeTax != null && incomeTax >= 0
              ? `${delimited(Math.floor(incomeTax))}円`
              : ''}
          </div>
        </div>
        <div className="angel-pattern-inr">
          <div className="angel-pattern-item">
            <h4 className="pattern-a">優遇措置Aを利用</h4>
            <div className="angel-pattern-item-col">
              <div className="angel-pattern-item-col-inr">
                <form>
                  <div className="form-group">
                    <label>総所得から控除できる額</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        angelTaxA != null && angelTaxA.deduction >= 0
                          ? `${delimited(Math.floor(angelTaxA.deduction))}円`
                          : ''
                      }
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label>エンジェル税制適用時の所得税額</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        angelTaxA != null && angelTaxA.incomeTax >= 0
                          ? `${delimited(Math.floor(angelTaxA.incomeTax))}円`
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </form>
              </div>
              <div className="arrow-block">
                <img height="26" width="106" alt="" src={BlueArrowPng} />
              </div>
              <div className="income-tax">
                <div className="value score-pattern-a">
                  <div className="score-text blue-icon">節税効果</div>
                  {angelTaxA != null && angelTaxA.refund >= 0 && (
                    <div>
                      {delimited(Math.floor(angelTaxA.refund))}
                      <span className="score-yen">円</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="angel-pattern-item">
            <h4 className="pattern-b">優遇措置Bを利用</h4>
            <div className="angel-pattern-item-col">
              <div className="angel-pattern-item-col-inr">
                <form>
                  <div className="form-group">
                    <label>総所得から控除できる額</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        angelTaxB != null && angelTaxB.deduction >= 0
                          ? `${delimited(Math.floor(angelTaxB.deduction))}円`
                          : ''
                      }
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label>エンジェル税制適用時の所得税額</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        angelTaxB != null && angelTaxB.incomeTax >= 0
                          ? `${delimited(Math.floor(angelTaxB.incomeTax))}円`
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </form>
              </div>
              <div className="arrow-block">
                <img height="26" width="106" alt="" src={GreenArrowPng} />
              </div>
              <div className="income-tax">
                <div className="value score-pattern-b">
                  <div className="score-text green-icon">節税効果</div>
                  {angelTaxB != null && angelTaxB.refund >= 0 && (
                    <div>
                      {delimited(Math.floor(angelTaxB.refund))}
                      <span className="score-yen">円</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="angel-under-text">
          本エンジェル税制税負担軽減シミュレーターは、税務申告に使用する為ではなく、仮定条件に基づく概算額の試算やそれに基づく情報提供を唯一の目的としています。実際の申告や各種株式の取得または譲渡等、プラン実行の際には、税理士等の専門家にご相談下さい。
        </p>
      </div>
    );
  }
}
